import React, { useRef } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import footerInformation from "../../data/footerInformation.json";
import footerLinks from "../../data/footerLinks.json";

const Footer = ({ formRef }) => {
  const { companyName, contact, officeAddress, legal } = footerInformation;
  const { locations } = footerLinks;
  const scrollToEnquiryForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="footer-section">
      <div className="footer-container">
        {/* Contact Column */}
        <div className="footer-column">
          <h3 className="footer-title">{companyName}</h3>
          <div className="footer-contact">
            <p>📞 Tel: {contact.phone}</p>
            <p>📧 {contact.email}</p>
            <p>Call hours: {contact.openingHours}</p>
          </div>
        </div>

        {/* Locations Column */}
        <div className="footer-column">
          <h3 className="footer-title">Locations</h3>
          <ul className="footer-links">
            {locations.map((location, index) => (
              <li key={index}>
                <a href={location.url}>{location.name}</a>
              </li>
            ))}
          </ul>
        </div>

        {/* Office Address and Enquire Button */}
        <div className="footer-column">
          <h3 className="footer-title">Office Address</h3>
          <div className="footer-contact">
            {officeAddress.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
          <button
            className="footer-enquire-button"
            onClick={scrollToEnquiryForm}
          >
            Enquire Now
          </button>
          <div className="footer-socials">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>
          All rights reserved - {companyName} {legal.year} | Company Reg. No.{" "}
          {legal.companyReg} / Vat No. {legal.vat} |{" "}
          <a href="#terms">Terms of Website Use</a> |{" "}
          <a href="#privacy">Privacy Policy</a> |{" "}
          <a href="#cookie">Cookie Policy</a>
        </p>
      </div>

      {/* Copyright Design */}
      <div className="footer-design">
        <div className="footer-design">
          <p>
            © 2024 SwaeSoftware. All rights reserved. Designed and developed
            with care by{" "}
            <a
              href="https://swaleh.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              SwaeSoftware
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
