import React, { useState, useRef, useEffect, forwardRef } from "react";
import emailjs from "emailjs-com";
import "./EnquiryForm.css";
import enquiryFields from "../../data/enquiryFields.json";
import { generateTimeOptions } from "../../utils/timeOptions";
import AutocompleteInput from "./AutoComplete/AutoComplete";
import Hero from "../hero/Hero";

const EnquiryForm = forwardRef(({ formRef }, ref) => {
  const [journeyType, setJourneyType] = useState("one-way");
  const [showAdditionalRows, setShowAdditionalRows] = useState(false);
  const additionalRowsRef = useRef(null);

  const [pickupPoint, setPickupPoint] = useState("");
  const [destination, setDestination] = useState("");
  const [viaPoints, setViaPoints] = useState([]);

  const [pickupDate, setPickupDate] = useState(""); // For pickup date
  const [returnDate, setReturnDate] = useState(""); // For return date

  const [pickupTime, setPickupTime] = useState("");
  const [returnTime, setReturnTime] = useState("");

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [promotionalConsent, setPromotionalConsent] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const isDateValid = (dateString) => {
    const selectedDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset today's time to midnight
    return selectedDate >= today; // Ensure the selected date is today or in the future
  };

  const isValidPhoneNumber = (phone) => {
    // UK phone number validation (landline or mobile)
    const phoneRegex = /^(?:\+44|0)\s?(?:\d\s?){9,10}$/;
    return phoneRegex.test(phone);
  };

  // Add your handleSubmit function here
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent page reload
    console.log("Form submitted"); // Debugging

    // Check if terms and conditions are accepted first
    if (!termsAccepted) {
      alert("You must agree to the Terms and Conditions before submitting.");
      return; // Stop submission
    }

    if (!formData.luggage) missingFields.push("Luggage Required");
    if (!formData.reason_for_trip) missingFields.push("Reason for Trip");

    const formatDate = (dateString) => {
      if (!dateString) return ""; // Return an empty string if the input is undefined or empty

      const [year, month, day] = dateString.split("-");
      if (!year || !month || !day) return ""; // Ensure all parts of the date exist
      return `${day}/${month}/${year}`;
    };

    // Collect form data
    const formData = {
      journey_type: journeyType,
      pickup_point: pickupPoint,
      destination,
      pickup_date: formatDate(pickupDate),
      pickup_time: pickupTime,
      return_date: journeyType === "return" ? formatDate(returnDate) : "N/A",
      return_time: journeyType === "return" ? returnTime : "N/A",
      via_points: viaPoints.map(
        (via) =>
          `${via.value} (Required for Return: ${
            via.requiredForReturn ? "Yes" : "No"
          })`
      ),
      full_name: document.querySelector("input[placeholder='Full Name']").value,
      email: document.querySelector("input[type='email']").value,
      phone: document.querySelector("input[type='tel']").value,
      luggage:
        document.querySelector("select[placeholder='Luggage Required']")
          ?.value || "",
      passengers: document.querySelector("input[type='number']")?.value || "",
      reason_for_trip:
        document.querySelector("select[placeholder='Reason for Trip']")
          ?.value || "",
      referral:
        document.querySelector(
          "select[placeholder='How Did You Hear About Us']"
        )?.value || "",
      discount_code:
        document.querySelector("input[placeholder='Discount Code']")?.value ||
        "",
      terms_accepted: termsAccepted ? "Yes" : "No",
    };

    // Validate required fields
    const missingFields = [];
    if (!formData.pickup_point) missingFields.push("Pickup Point");
    if (!formData.destination) missingFields.push("Destination");
    if (!formData.pickup_date) missingFields.push("Pickup Date");
    if (!formData.pickup_time) missingFields.push("Pickup Time");
    if (journeyType === "return" && !formData.return_date)
      missingFields.push("Return Date");
    if (journeyType === "return" && !formData.return_time)
      missingFields.push("Return Time");
    if (!formData.full_name) missingFields.push("Full Name");
    if (!formData.email) missingFields.push("Email");
    if (!formData.phone) missingFields.push("Phone");
    if (!termsAccepted) missingFields.push("Accept Terms and Conditions");

    // Check phone number validity
    if (!isValidPhoneNumber(formData.phone)) {
      alert("Please enter a valid UK phone number.");
      return; // Stop submission
    }

    if (missingFields.length > 0) {
      alert(
        `Please complete the following required fields:\n\n${missingFields.join(
          "\n"
        )}`
      );
      return; // Stop submission if validation fails
    }

    // Send email via EmailJS
    emailjs
      .send(
        "service_pq6suar", // Replace with your EmailJS service ID
        "template_oa9a6iy", // Replace with your EmailJS template ID
        formData,
        "_oiQwdP8-8VZgdvY4" // Replace with your EmailJS public key (User ID)
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Your enquiry has been submitted successfully!");
          resetForm(); // Reset the form after submission
        },
        (error) => {
          console.error("Error sending email:", error.text);
          alert("Failed to send your enquiry. Please try again later.");
        }
      );
  };

  // Reset the form after successful submission
  const resetForm = () => {
    setJourneyType("one-way");
    setPickupPoint("");
    setDestination("");
    setViaPoints([]);
    setPickupDate("");
    setReturnDate("");
    setPickupTime("");
    setReturnTime("");
    setTermsAccepted(false);
    setPromotionalConsent(false);
    setPrivacyPolicyAccepted(false);
  };

  const handleToggle = () => {
    setTermsAccepted(!termsAccepted);
  };

  useEffect(() => {
    if (additionalRowsRef.current) {
      if (showAdditionalRows) {
        const contentHeight = additionalRowsRef.current.scrollHeight;
        additionalRowsRef.current.style.maxHeight = `${contentHeight}px`;
      } else {
        additionalRowsRef.current.style.maxHeight = "0px";
      }
    }
  }, [showAdditionalRows]);

  const addViaPoint = () => {
    setViaPoints([...viaPoints, { id: viaPoints.length + 1, value: "" }]);
  };

  const removeViaPoint = (id) => {
    setViaPoints(viaPoints.filter((point) => point.id !== id));
  };

  const updateViaPoint = (id, value) => {
    setViaPoints(
      viaPoints.map((point) => (point.id === id ? { ...point, value } : point))
    );
  };

  const toggleViaPointReturn = (id) => {
    setViaPoints(
      viaPoints.map((point) =>
        point.id === id
          ? { ...point, requiredForReturn: !point.requiredForReturn }
          : point
      )
    );
  };

  const handleRow3Interaction = () => {
    setShowAdditionalRows(true);
  };

  return (
    <div className="hero-wrapper">
      <Hero />
      <section className="enquiry-section" ref={formRef}>
        <div className="enquiry-content">
          <h1>Enquiry Form</h1>
          <p>Use our online enquiry form and receive a 5% discount.</p>
          <form className="enquiry-form" onSubmit={handleSubmit}>
            {/* Journey Type Buttons */}
            <div className="journey-buttons">
              {enquiryFields.journeyType.options.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  className={`journey-button ${
                    journeyType === option.value ? "active" : ""
                  }`}
                  onClick={() => setJourneyType(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
            {/* Row 1 */}
            <div className="form-row">
              {/* Pickup Point */}
              <div className="form-group pickup-point">
                <AutocompleteInput
                  id="pickupPoint"
                  name="pickupPoint"
                  placeholder="Pickup Point (Please provide full address and postcode)"
                  value={pickupPoint}
                  onChange={setPickupPoint}
                />
              </div>
              <div className="form-group date-wrapper">
                <input
                  type="date"
                  value={pickupDate} // Use the state variable
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isDateValid(value)) {
                      setPickupDate(value); // Update state if the date is valid
                    } else {
                      alert("The pickup date must be today or in the future."); // Display an alert
                    }
                  }}
                  required
                />
              </div>
              <div className="form-group time">
                <select
                  value={pickupTime} // Bind this to the state variable for time
                  onChange={(e) => setPickupTime(e.target.value)} // Update state on change
                  required
                >
                  <option value="" disabled>
                    Departure Time
                  </option>
                  {generateTimeOptions().map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Dynamic Via Points */}
            {viaPoints.map((viaPoint) => (
              <div key={viaPoint.id} className="form-row via-point-row">
                <div className="form-group">
                  <AutocompleteInput
                    id={`viaPoint${viaPoint.id}`}
                    name={`viaPoint${viaPoint.id}`}
                    placeholder={`Via Point ${viaPoint.id}`}
                    value={viaPoint.value}
                    onChange={(value) => updateViaPoint(viaPoint.id, value)}
                  />
                </div>
                <div className="form-group via-point-remove">
                  <button
                    type="button"
                    className="remove-via-point-button"
                    onClick={() => removeViaPoint(viaPoint.id)}
                  >
                    ✖
                  </button>
                </div>
                <div className="form-group via-point-return-checkbox">
                  <input
                    type="checkbox"
                    id={`via-return-${viaPoint.id}`}
                    checked={viaPoint.requiredForReturn}
                    onChange={() => toggleViaPointReturn(viaPoint.id)}
                  />
                  <label htmlFor={`via-return-${viaPoint.id}`}>
                    Required for return?
                  </label>
                </div>
              </div>
            ))}
            {/* Via Point Button */}
            <div className="via-point-container">
              <button
                type="button"
                className="via-point-button"
                onClick={addViaPoint}
              >
                + Via Point
              </button>
            </div>
            {/* Row 2 */}
            <div
              className={`form-row destination ${
                journeyType === "return" ? "return-trip" : ""
              }`}
            >
              <div className="form-group destination">
                <AutocompleteInput
                  id="destination"
                  name="destination"
                  placeholder="Destination (Please provide full address and postcode)"
                  value={destination}
                  onChange={setDestination}
                />
              </div>
              {journeyType === "return" && (
                <>
                  <div className="form-group return-date">
                    <input
                      type="date"
                      value={returnDate} // Use the state variable
                      onChange={(e) => {
                        const value = e.target.value;
                        if (isDateValid(value)) {
                          setReturnDate(value); // Update state if the date is valid
                        } else {
                          alert(
                            "The return date must be today or in the future."
                          ); // Display an alert
                        }
                      }}
                      required={journeyType === "return"} // Required only for return journeys
                    />
                  </div>
                  <div className="form-group return-time">
                    <select
                      value={returnTime} // Bind this to the state variable for return time
                      onChange={(e) => setReturnTime(e.target.value)} // Update state on change
                      required
                    >
                      <option value="" disabled>
                        Return Time
                      </option>
                      {generateTimeOptions().map((time) => (
                        <option key={time} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>
            <hr className="section-separator" />
            {/* Row 3 */}
            <div className="form-row contact-info">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Full Name"
                  required
                  onFocus={handleRow3Interaction}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  required
                  onFocus={handleRow3Interaction}
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  required
                  onFocus={handleRow3Interaction}
                />
              </div>
            </div>
            {/* Animated Rows */}
            <div className="additional-rows" ref={additionalRowsRef}>
              <hr className="section-separator" />
              {/* Row 4 */}
              <div className="form-row">
                <div className="form-group">
                  <select required>
                    <option value="">Luggage Required</option>
                    {enquiryFields.luggageOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    placeholder="Number of Passengers"
                    required
                  />
                </div>
                <div className="form-group">
                  <select required>
                    <option value="" disabled selected>
                      Reason for Trip
                    </option>
                    {enquiryFields.tripReasons.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Row 5 */}

              <div className="form-row" style={{ marginTop: "20px" }}>
                <div className="form-group">
                  <select>
                    <option value="" disabled selected>
                      How Did You Hear About Us?
                    </option>
                    {enquiryFields.referralOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Discount Code" />
                </div>
              </div>
              <hr className="section-separator" />
              {/* Row 6 */}
            </div>
            <div className="submit-section">
              {/* Terms and Conditions Toggles */}
              <div className="form-checkbox-group">
                {enquiryFields.checkboxes.map((checkbox) => (
                  <div key={checkbox.name} className="toggle-container">
                    <div
                      className={`toggle-switch ${
                        checkbox.name === "terms" && termsAccepted
                          ? "active"
                          : checkbox.name === "specialOffers" &&
                            promotionalConsent
                          ? "active"
                          : checkbox.name === "moreInfo" &&
                            privacyPolicyAccepted
                          ? "active"
                          : ""
                      }`}
                      role="switch"
                      aria-checked={
                        checkbox.name === "terms"
                          ? termsAccepted
                          : checkbox.name === "specialOffers"
                          ? promotionalConsent
                          : privacyPolicyAccepted
                      }
                      onClick={() =>
                        checkbox.name === "terms"
                          ? setTermsAccepted(!termsAccepted)
                          : checkbox.name === "specialOffers"
                          ? setPromotionalConsent(!promotionalConsent)
                          : setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                      }
                    ></div>
                    <span className="toggle-label">{checkbox.label}</span>
                  </div>
                ))}
              </div>

              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="submit-button"
                  disabled={
                    !termsAccepted
                  } /* Disable button until terms are accepted */
                >
                  Submit Enquiry
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
});

export default EnquiryForm;
