import React, { useRef } from "react";
import "./styles/App.css";
import Header from "./components/Header/Header";
import EnquiryForm from "./components/EnquiryForm/EnquiryForm";
import Reviews from "./components/Reviews/Reviews";
import Hero from "./components/hero/Hero";
import CoachHireBanner from "./components/Banner/CoachHireBanner";
import ServiceCards from "./components/Cards/ServiceCards";
import FeaturesSection from "./components/Features/FeaturesSection";
import VehiclesSection from "./components/Vehicles/VehiclesSection";
import CoachNearYou from "./components/NearBanner/CoachNearYou";
import Footer from "./components/Footer/Footer";

const App = () => {
  const enquiryFormRef = useRef(null); // Create the ref

  return (
    <div className="App">
      <Header />
      <EnquiryForm formRef={enquiryFormRef} />
      <CoachHireBanner />
      <ServiceCards formRef={enquiryFormRef} />
      <Reviews />
      <VehiclesSection />
      <CoachNearYou formRef={enquiryFormRef} />
      <FeaturesSection />
      <Footer formRef={enquiryFormRef} />
    </div>
  );
};

export default App;
