import React from "react";
import "./Header.css";
import logo from "../../images/Logo.png";
import headerInfo from "../../data/headerinfo.json"; // Adjust path as needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faHandshake } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const { phone, workWithUs, socials } = headerInfo; // Destructure data from JSON

  return (
    <header className="header">
      <div className="header-container">
        {/* Desktop Contact Section */}
        <div className="header-contact">
          <a href={`tel:${phone.number}`} className="header-phone">
            <FontAwesomeIcon icon={faPhone} size="sm" />
            <span style={{ marginLeft: "8px" }}>{phone.label}</span>
          </a>
          <a
            href={workWithUs.url}
            target="_blank"
            rel="noopener noreferrer"
            className="header-work-with-us"
          >
            <FontAwesomeIcon icon={faHandshake} size="sm" />
            <span style={{ marginLeft: "8px" }}>{workWithUs.label}</span>
          </a>
        </div>

        {/* Logo Section */}
        <div className="header-logo">
          <a href="/">
            <img src={logo} alt="Your Logo" />
          </a>
        </div>

        {/* Social Media Icons */}
        <div className="header-socials">
          <a
            href={socials.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faFacebook} size="lg" />
          </a>
          <a
            href={socials.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
          <a
            href={`https://wa.me/${socials.whatsappNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faWhatsapp} size="lg" />
          </a>
        </div>

        {/* Mobile Contact Section (Separate Icons) */}
        <div className="header-contact-mobile">
          <a href={`tel:${phone.number}`} className="mobile-phone-icon">
            <FontAwesomeIcon icon={faPhone} size="lg" />
          </a>
          <a
            href={workWithUs.url}
            target="_blank"
            rel="noopener noreferrer"
            className="mobile-handshake-icon"
          >
            <FontAwesomeIcon icon={faHandshake} size="lg" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
