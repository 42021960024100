import React, { useState } from "react";
import "./Reviews.css";
import reviewsData from "../../data/reviewsData.json";

const Reviews = () => {
  const [reviews, setReviews] = useState([...reviewsData]); // Maintain dynamic reviews
  const [currentIndex, setCurrentIndex] = useState(0); // Track current review index

  const handleNext = () => {
    setReviews((prevReviews) => {
      const firstReview = prevReviews[0];
      return [...prevReviews.slice(1), firstReview];
    });
  };

  const handlePrev = () => {
    setReviews((prevReviews) => {
      const lastReview = prevReviews[prevReviews.length - 1];
      return [lastReview, ...prevReviews.slice(0, -1)];
    });
  };

  return (
    <section className="reviews-component-section">
      <h2 className="reviews-component-title">Coach Hire Reviews</h2>
      <div className="reviews-component-wrapper">
        <button
          className="reviews-component-arrow reviews-component-left-arrow"
          onClick={handlePrev}
        >
          &#8249;
        </button>
        <div className="reviews-component-container">
          <div className="reviews-component-card">
            <h3 className="reviews-component-name">{reviews[0].name}</h3>
            <p className="reviews-component-text">{reviews[0].review}</p>
            <div className="reviews-component-stars">
              {"★".repeat(reviews[0].stars)}
              {"☆".repeat(5 - reviews[0].stars)}
            </div>
          </div>
        </div>
        <button
          className="reviews-component-arrow reviews-component-right-arrow"
          onClick={handleNext}
        >
          &#8250;
        </button>
      </div>
    </section>
  );
};

export default Reviews;
