import React from "react";
import "./CoachHireBanner.css";
import coachHireData from "../../data/coachHireData.json";
import PhoneCouple from "../../images/PhoneCouple.png"; // Import image directly

const CoachHireBanner = () => {
  return (
    <section className="coach-hire-banner">
      <div className="banner-wrapper">
        <div className="banner-left">
          <h1 className="banner-title">{coachHireData.title}</h1>
          <h4 className="banner-subtitle">{coachHireData.subtitle}</h4>
          <p className="banner-description">{coachHireData.description}</p>
        </div>
        <div className="banner-right">
          <img
            className="banner-image"
            src={PhoneCouple} // Use the imported image
            alt={coachHireData.imageAlt}
          />
          <div className="review-box">
            <div className="review-details">
              <p className="review-name">{coachHireData.review.name}</p>
              <div className="review-stars">{coachHireData.review.stars}</div>
              <p className="review-text">"{coachHireData.review.text}"</p>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoachHireBanner;
