import React from "react";

/**
 * Generates a list of <option> elements for time selection.
 * @returns {JSX.Element[]} An array of <option> elements.
 */
export const generateTimeOptions = () => {
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const formattedTime = `${hour.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      timeOptions.push(formattedTime);
    }
  }
  return timeOptions;
};
