import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";

const AutocompleteInput = ({ id, name, placeholder, value, onChange }) => {
  const [isTyping, setIsTyping] = useState(false); // Track if the user is typing

  const handleInputChange = (e) => {
    setIsTyping(true);
    onChange(e.target.value); // Update the value as the user types
  };

  const handlePlaceChanged = () => {
    const place = window[id || placeholder].getPlace();
    if (!place || !place.formatted_address) return;

    setIsTyping(false);
    onChange(place.formatted_address); // Update with the selected place
  };

  return (
    <Autocomplete
      onLoad={(autocomplete) => {
        // Load the Autocomplete instance into a global variable for the current field
        window[id || placeholder] = autocomplete;

        // Apply component restrictions to limit to UK addresses
        autocomplete.setComponentRestrictions({ country: "uk" });
      }}
      onPlaceChanged={handlePlaceChanged}
    >
      <input
        type="text"
        id={id}
        name={name || id}
        className="autocomplete-input"
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        required
      />
    </Autocomplete>
  );
};

export default AutocompleteInput;
