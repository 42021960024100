import React from "react";
import "./VehiclesSection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

// Import images
import luxuryCoach from "../../images/vehicles/luxury-coach.png";
import standardMinibus from "../../images/vehicles/standard-minibus.png";
import accessibleMinibus from "../../images/vehicles/accessible-minibus.png";

// Import data
import vehiclesData from "../../data/vehiclesData.json";

const VehiclesSection = () => {
  const {
    sectionTitle,
    sectionSubtitle,
    sectionDescription,
    vehicles,
    buttonText,
  } = vehiclesData;

  // Image mapping
  const vehicleImages = [luxuryCoach, standardMinibus, accessibleMinibus];

  return (
    <section className="vehicles-section">
      <div className="vehicles-header">
        <h3>{sectionTitle}</h3>
        <h2>{sectionSubtitle}</h2>
        <p>{sectionDescription}</p>
      </div>

      {/* Grid for Desktop, Swiper for Mobile */}
      <div className="vehicles-content">
        {/* Desktop View */}
        <div className="vehicles-grid">
          {vehicles.map((vehicle, index) => (
            <div key={index} className="vehicle-card">
              <img src={vehicleImages[index]} alt={vehicle.name} />
              <h4>{vehicle.name}</h4>
              <p>{vehicle.description}</p>
            </div>
          ))}
        </div>

        {/* Mobile Carousel */}
        <div className="vehicles-carousel">
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
              el: ".vehicles-swiper-pagination", // Custom pagination container
            }}
            spaceBetween={20}
            slidesPerView={1}
          >
            {vehicles.map((vehicle, index) => (
              <SwiperSlide key={index}>
                <div className="vehicle-card">
                  <img src={vehicleImages[index]} alt={vehicle.name} />
                  <h4>{vehicle.name}</h4>
                  <p>{vehicle.description}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Explicit custom pagination container */}
          <div className="vehicles-swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};

export default VehiclesSection;
