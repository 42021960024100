import React, { useRef } from "react";
import "./ServiceCards.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import servicesData from "../../data/servicesData.json";
import AirportImage from "../../images/cards/airport.png";
import WeddingImage from "../../images/cards/wedding.png";
import FootballImage from "../../images/cards/football.png";

// Map keys to imported images
const imageMap = {
  airport: AirportImage,
  wedding: WeddingImage,
  football: FootballImage,
};

const ServiceCards = ({ formRef }) => {
  const scrollToEnquiryForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="service-cards">
      {/* Section Header */}
      <h3 className="section-tagline">
        Coach Hire and Minibus Hire for Every Occasion
      </h3>
      <h2 className="section-title">Coach Hire & Minibus Hire Services</h2>
      <p className="section-description">
        We provide comfortable, affordable transport solutions for all
        occasions.
      </p>

      {/* Desktop Grid Layout */}
      <div className="desktop-cards-container">
        {servicesData.map((service, index) => (
          <div className="desktop-service-card" key={index}>
            <img
              src={imageMap[service.imageKey]}
              alt={service.altText}
              className="desktop-card-image"
            />
            <h4 className="desktop-card-title">{service.title}</h4>
            <p className="desktop-card-description">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Mobile Carousel */}
      <div className="mobile-cards-container">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-swiper-pagination", // Explicit pagination container
          }}
          spaceBetween={30}
          slidesPerView={1}
        >
          {servicesData.map((service, index) => (
            <SwiperSlide key={index}>
              <div className="mobile-modern-card">
                <div className="mobile-card-header">
                  <img
                    src={imageMap[service.imageKey]}
                    alt={service.altText}
                    className="mobile-card-image"
                  />
                </div>
                <div className="mobile-card-content">
                  <h4 className="mobile-card-title">{service.title}</h4>
                  <p className="mobile-card-description">
                    {service.description}
                  </p>
                </div>
                <div className="mobile-card-footer">
                  <button
                    className="mobile-card-button"
                    onClick={scrollToEnquiryForm}
                  >
                    {service.buttonText}
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="custom-swiper-pagination" />
      </div>

      {/* Single Button Below Cards */}
      <div className="enquire-button-container">
        <button className="desktop-card-button" onClick={scrollToEnquiryForm}>
          Enquire Now
        </button>{" "}
      </div>
    </section>
  );
};

export default ServiceCards;
