import React from "react";
import ReactDOM from "react-dom/client"; // Correct use of ReactDOM in React 18+
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { LoadScript } from "@react-google-maps/api";

const API_KEY = "AIzaSyBN8eQ5yez-yZkueDZBivrY93EtnzkXz4w";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
      <App />
    </LoadScript>
  </React.StrictMode>
);

// Measure performance
reportWebVitals();
