import React from "react";
import "./FeaturesSection.css";
import featuresData from "../../data/featuresData.json";
import * as Icons from "react-icons/md";

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <h2 className="features-title">Quality Coach Hire You'll Love</h2>
      <div className="features-grid">
        {featuresData.map((feature, index) => {
          const IconComponent = Icons[feature.icon];
          return (
            <div className="feature-card" key={index}>
              <div className="feature-icon">
                <IconComponent />
              </div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FeaturesSection;
