import React from "react";
import "./CoachNearYou.css";
import content from "../../data/coachContent.json";

const CoachNearYou = ({ formRef }) => {
  const scrollToEnquiryForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const renderTextWithHighlights = (text, highlights) => {
    if (!highlights.length) return text;

    // Split text into parts based on highlights
    const regex = new RegExp(`(${highlights.join("|")})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      highlights.includes(part) ? (
        <strong key={index} className="highlighted">
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  return (
    <section className="coach-near-you-section">
      <div className="coach-near-you-content">
        <h1>{content.title}</h1>
        {content.paragraphs.map((paragraph, index) => (
          <p key={index}>
            {renderTextWithHighlights(paragraph.text, paragraph.highlights)}
          </p>
        ))}
        <div className="coach-near-you-button-container">
          <button
            className="coach-near-you-button"
            onClick={scrollToEnquiryForm}
          >
            {content.buttonText}
          </button>
        </div>
      </div>
    </section>
  );
};

export default CoachNearYou;
